.assess-table[data-v-52379ff4] {
  width: 100%;
}
.assess-table[data-v-52379ff4] .rowSpanCell {
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #d9dddf;
}
.assess-table[data-v-52379ff4] .ag-row-last .rowSpanCell {
  border-bottom: 0;
}
.assess-table[data-v-52379ff4] .ag-header-group-cell-label .ag-header-group-text {
  width: 100%;
  text-align: center;
}
