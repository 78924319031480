.header-container[data-v-770be69e] {
  display: flex;
  flex-direction: var(--770be69e-flexDirection);
  justify-content: space-between;
}
.header-container span[data-v-770be69e] {
  margin-right: 16px;
  font-weight: bolder;
}
.header-container .left-container > div[data-v-770be69e] {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: var(--770be69e-margin);
}
