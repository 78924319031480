.header-container[data-v-5401bdf3] {
  display: flex;
  flex-direction: var(--5401bdf3-flexDirection);
  justify-content: space-between;
  margin-bottom: 8px;
}
.header-container span[data-v-5401bdf3] {
  margin-right: 16px;
  font-weight: bolder;
}
.header-container .left-container > div[data-v-5401bdf3] {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: var(--5401bdf3-margin);
}
